import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Downline = lazy(() => import('./dashboard/Downline'));
const Downlinedetails = lazy(() => import('./dashboard/Downlinedetails'));
const Forgotpassword = lazy(() => import('./user-pages/Forgotpassword'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));


class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
		
		
		<Route  path="/register" component={ Register1 } />
          <Route  path="/dashboard" component={ Dashboard } />
		 <Route  path="/forgotpassword" component={ Forgotpassword } />
		   
          <Route  path="/downline/:downline" component={ Downline } />
          <Route  path="/Downlinedetails/:id" component={ Downlinedetails } />

          <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />

          <Route path="/form-Elements/basic-elements" component={ BasicElements } />

          <Route path="/tables/basic-table" component={ BasicTable } />

          <Route path="/icons/mdi" component={ Mdi } />

          <Route path="/charts/chart-js" component={ ChartJs } />


         

          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />
		<Route   path={["/:id?","/login"]} component={ Login } />

        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
